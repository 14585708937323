import * as React from 'react';
import {useState} from 'react';
import './LocationDetailsPageComponent.scss';
import {Header} from '../components/header/Header';
import {Footer} from '../components/footer/Footer';
import Container from 'react-bootstrap/esm/Container';
import {graphql, PageProps, useStaticQuery} from 'gatsby';
import {PageHead} from '../components/page-head/PageHead';
import { LocationDetailsPage_allGuidesJson_nodes, LocationDetailsPage, LocationDetailsPage_allGalleriesJson_edges_node_images } from './__generated__/LocationDetailsPage';
import {GatsbyImage, IGatsbyImageData, StaticImage} from 'gatsby-plugin-image';
import { QAllImages } from '../components/QAllImages/QAllImages';
import { AllImages } from '../components/QAllImages/__generated__/AllImages';
import { getImageData } from '../utils/data';
import { Row } from 'react-bootstrap';
import { baseClassName } from '../utils/classNames';

type PageContext = {
  id: string;
};

type Props = {
  pageContext: PageProps & PageContext;
};

type Guide = LocationDetailsPage_allGuidesJson_nodes;
type ImageMeta = LocationDetailsPage_allGalleriesJson_edges_node_images;

const MemooizedGatsbyImage = React.memo(({imageData, description}: {imageData: IGatsbyImageData, description: string}) => {
  return <GatsbyImage style={{height: '100%'}} image={imageData} alt={description}/>;
}, () => true);

const LocationDetailsPageComponent = ({pageContext: {id}}: Props) => {
  const query: LocationDetailsPage = useStaticQuery(graphQuery);


  const guide: Guide | null = query
    .allGuidesJson
    .nodes
    .find(node => node.id === id) || null;

  if (guide === null) {
    return <div>{`Error. Page cannot be created. Guide.ID=${id} is missing.`}</div>;
  }

  const imageMeta: ImageMeta | null = query
    .allGalleriesJson
    .edges
    .flatMap(edge => edge.node.images)
    .find(item => item?.imageName === guide.imageName) || null;


  if (imageMeta === null) {
    return <div>{`Error. Page cannot be created. ImageMetadata=${guide.imageName} not found`}</div>;
  }

  const [isViewfinderActive, setViewFinder] = useState(false);
  const metadataClass = baseClassName('metadata');

  return (
    <>
      <PageHead/>
      <Header />
      <Container>
        <QAllImages render={(allImages: AllImages) =>
          <>
            <h2 className="text-center">{guide.title}</h2>
            <Row>
              <div className="col-lg-12">
                <div className="image-box">
                  <MemooizedGatsbyImage
                    imageData={getImageData(guide.imageName, allImages)}
                    description={guide.description ?? 'No Description'}
                  />
                  <div className="viewfinder" onClick={() => setViewFinder(!isViewfinderActive)}>
                    <StaticImage className="icon" src="../images/common/viewfinder.png" alt="Viewfinder Icon"/>
                  </div>
                  <div className={metadataClass({active: isViewfinderActive})}>
                    <div className="line horizontal-top"></div>
                    <div className="line horizontal-bottom"></div>
                    <div className="line vertical-left"></div>
                    <div className="line vertical-right"></div>
                    <div className="top-data">
                      <div>{imageMeta.make} {imageMeta.model} {imageMeta.lens}</div>
                      <div>Equivalent full frame focal length: {imageMeta.focalLangthFullFrame}mm</div>
                    </div>
                    <div className="bottom-data">
                      <div className="focal-length"><strong>FL</strong>{imageMeta.focalLength}</div>
                      <div className="shutter-speed"><strong>SS</strong>{imageMeta.shutterSpeed}s</div>
                      <div className="aperture"><strong>F</strong>{parseInt(imageMeta.aperture ?? '0')}</div>
                      <div className="iso"><strong>ISO</strong>{imageMeta.iso}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <section className="col-lg-12">
                  <h4>
                    <StaticImage className="icon" src="../images/common/information.png" alt="Description Icon" />
                    Description
                  </h4>
                  <p>{guide.description}</p>
              </section>
            </Row>
            <Row className="embeded-map-row">
              <section className="col-xl-8 col-lg-6">
                  <h4>
                    <StaticImage className="icon" src="../images/common/location.png" alt="Location Icon" />
                    Directions
                  </h4>
                  <p>{guide.directions}</p>
                  <h4>
                    <StaticImage className="icon" src="../images/common/satelite.png" alt="GPS Coordinates Icon" />
                    GPS Coordinates
                  </h4>
                  <p>{guide.gps}</p>
              </section>
              <div className="col-xl-4 col-lg-6">
                {guide.mapLink !== null && <iframe src={guide.mapLink ?? ''} width="100%" height="100%"></iframe>}
              </div>
            </Row>
            <Row>
              <section className="col-lg-12">
                  <h4>
                    <StaticImage className="icon" src="../images/common/lens.png" alt="Lens Icon" />
                    Lens & Equipment
                  </h4>
                  <p>{guide.lensAndEquipment}</p>
              </section>
            </Row>
            <Row>
              <section className="col-lg-12">
                  <h4>
                    <StaticImage className="icon" src="../images/common/calendar.png" alt="Calendar Icon" />
                    Time of the year
                  </h4>
                  <p>{guide.timeOfTheYear}</p>
              </section>
            </Row>
            <Row>
              <section className="col-lg-12">
                  <h4>
                    <StaticImage className="icon" src="../images/common/clock.png" alt="Clock Icon" />
                    Time of the day
                  </h4>
                  <p>{guide.timeOfTheDay}</p>
              </section>
            </Row>
          </>}
        />
      </Container>
      <Footer />
    </>
  );
};

const graphQuery = graphql`
  query LocationDetailsPage {
    allGuidesJson {
      nodes {
        id
        imageName
        title
        description
        directions
        timeOfTheYear
        timeOfTheDay
        lensAndEquipment
        mapLink
        gps
      }
    }
    allGalleriesJson {
      edges {
        node {
          images {
            aperture
            description
            focalLength
            focalLangthFullFrame
            lens
            width
            shutterSpeed
            model
            make
            iso
            imageName
            height
          }
        }
      }
    }
  }`;

export default LocationDetailsPageComponent;
